// Callout
$callout-border-width: $border-width * 4 !default;
$callout-background: $light !default;
$callout-border-radius: $border-radius * 0.25 !default;
$callout-padding-y: $spacer * 1.5 !default;
$callout-padding-x: $spacer * 2 !default;
$callout-border: $primary !default;
$callout-border-style: solid !default;
$callout-margin-bottom : 1.5rem;

.callout{
    border-left-width: $callout-border-width;
    border-left-style: $callout-border-style;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-top-width: 0px;
    border-color: $callout-border;
    background: $callout-background;
    border-radius: $callout-border-radius;
    padding-top: $callout-padding-y;
    padding-bottom: $callout-padding-y;
    padding-right: $callout-padding-x;
    padding-left: $callout-padding-x;
}

// Generate contextual modifier classes for colorizing the callout.

@each $state, $value in $theme-colors {
    .callout-#{$state} {
      @include callout-variant($value);
    }
  }