// Mixin for Callout
@mixin callout-variant($border-color) {
    border-color: $border-color;
};

// Mixin for badge
@mixin gradient-bg($color: null) {
    background-color: $color;
    @if $enable-gradients {
        background-image: var(--#{$variable-prefix}gradient);
    }
}
@mixin badge-variant($background, $color) {
    color: $color;
    @include gradient-bg($background);
    .badge-link {
        color: shade-color($color, 30%);
    }
}