
.accordion-header{
    border-left:4px solid $primary;
    margin-top:5px;

}
.accordion-item{
    border-width: 0;
    .accordion-button{
        padding: 1rem 1.25rem;
    }
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 0;
}