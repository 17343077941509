// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ForeignAdmits-primary: mat.define-palette(mat.$indigo-palette);
$ForeignAdmits-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$ForeignAdmits-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ForeignAdmits-theme: mat.define-light-theme((color: (primary: $ForeignAdmits-primary, accent: $ForeignAdmits-accent, warn: $ForeignAdmits-warn, ), ));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ForeignAdmits-theme);

/* You can add global styles to this file, and also import other style files */

@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "./scss/fonts";
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";
// 2. Include any default variable overrides here
@import "../src/scss/variables";
// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
// 4. Include any optional Bootstrap components as you like
// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "swiper/css/bundle";
@import "scss/fixed-plugin";
// Helpers
@import "bootstrap/scss/helpers";
// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack
// 5. Add additional custom code here
//customize css
@import "../src/scss/components";
@import "bootstrap-icons/font/bootstrap-icons";
@import "animate.css/animate.css";
@import url("ngx-toastr/toastr");
@import url("@suprsend/ngx-inbox/assets/styles.css");
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: $font-family !important;
}

::-webkit-scrollbar {
    height: 10px;
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #D3D3D4;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #BCBEBF;
    border-radius: 5px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #BCBEBF;
}

form label span {
    color: #DC3545;
}

button:focus {
    box-shadow: none !important;
}

input:focus,
select:focus {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
}

.mat-form-field-underline {
    height: 0px !important;
}

.separate-dial-code {
    width: 100%;
}

.iti__selected-flag {
    width: auto !important;
}

.iti.separate-dial-code input {
    padding-left: 50px !important;
}

// suprsend
#ssPopperMenu {
    background: #333;
    color: white;
    font-weight: bold;
    font-family: "Poppins", Times, serif;
    font-size: 13px;
    border-radius: 4px;
    display: none;
}

#ssPopperMenu[data-show] {
    display: block;
}

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
}

#arrow::before {
    content: "";
    transform: rotate(45deg);
    background: #333;
}

#ssPopperMenu[data-popper-placement^="top"]>#arrow {
    bottom: -4px;
}

#ssPopperMenu[data-popper-placement^="bottom"]>#arrow {
    top: -4px;
}

#ssPopperMenu[data-popper-placement^="left"]>#arrow {
    right: -4px;
}

#ssPopperMenu[data-popper-placement^="right"]>#arrow {
    left: -4px;
}

#ssPopperMenu {
    background: white;
    color: #00558C;
    font-weight: bold;
    font-size: 13px;
    border-radius: 4px;
    display: none;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Poppins", Times, serif;
}

#ssPopperMenu[data-show] {
    display: block;
    z-index: 501;
}

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
}

#arrow::before {
    content: "";
    transform: rotate(45deg);
    background: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#ssPopperMenu[data-popper-placement^="top"]>#arrow {
    bottom: -4px;
}

#ssPopperMenu[data-popper-placement^="bottom"]>#arrow {
    top: -4px;
}

#ssPopperMenu[data-popper-placement^="left"]>#arrow {
    right: -4px;
}

#ssPopperMenu[data-popper-placement^="right"]>#arrow {
    left: -4px;
}

.ss-text {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    font-family: "Poppins", Times, serif;
}

.ss-badge {
    font-size: 10px;
    display: inline-block;
    position: absolute;
    right: -3px;
    top: -7px;
    padding: 3px 6px;
    background-color: red;
    color: white;
    text-align: center;
    border-radius: 50%;
}

.ss-bell {
    height: 35px;
    width: 24px;
    color: #000;
}

.ss-popper-container {
    min-height: 100px;
    max-height: 400px;
    width: 350px;
    overflow: scroll;
}

@media (max-width: 425px) {
    .ss-popper-container {
        width: 99.5vw;
        margin: 0px;
        border-radius: 0px;
    }
}

.ss-notification-container {
    padding: 7px 14px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}

.ss-notification-container:hover {
    background-color: #f0f0f0;
}

.ss-popper-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 12px;
    border-bottom: 1px solid #f0f0f0;
    z-index: 999;
}

.ss-popper-header-text {
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", Times, serif;
    color: #2f455c
}

.ss-no-notifications-text {
    text-align: center;
    font-style: italic;
    margin: 20px 0px;
    background-color: transparent;
    color: #707070;
}

.ss-notification-header-text {
    font-size: 16px;
    margin: 10px 0px;
    white-space: pre-line;
    font-family: "Poppins", Times, serif;
    color: #2f455c
}

.ss-notification-body-text {
    margin: 10px 0px;
    white-space: pre-line;
    font-family: "Poppins", Times, serif;
    color: #BCBEBF;
}

.ss-notification-unseendot {
    background: #008dea;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    margin-top: 18px;
}

.ss-action-primary {
    width: 150px;
    background: #008dea;
    border-radius: 5px;
    text-decoration: none;
    font-family: "Poppins", Times, serif;
}

.ss-action-primary-text {
    color: white;
    padding: 5px 0px;
    text-align: center;
    word-break: break-all;
    font-family: "Poppins", Times, serif;
}

.ss-action-outline {
    width: 150px;
    background: #008dea;
    border-radius: 5px;
    text-decoration: none;
    border-color: #008dea;
    border-style: solid;
    border-width: 1px;
    background: white;
}

.ss-action-outline-text {
    color: #008dea;
    padding: 5px 0px;
    text-align: center;
    word-break: break-all;
    font-family: "Poppins", Times, serif;
}

.ss-notification-created-text {
    font-size: 12px;
    margin: 0px;
    color: #707070;
    font-weight: 400;
}

.toast-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
    color: white;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Poppins", Times, serif;
}

.toast-message {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    color: white;
    font-family: "Poppins", Times, serif;
    word-wrap: break-word;
    margin-top: 0px;
}

.toast-success {
    background-color: #198754;
    color: white;
    background-image: none;
}

.toast-container .ngx-toastr {
    padding-left: 15px;
    max-width: 450px;
    min-width: 300px;
    width: auto;
    font-family: "Poppins", Times, serif;
}

@media (max-width: 425px) {
    .toast-message {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0px;
        font-family: "Poppins", Times, serif;
    }
    .toast-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0px;
        font-family: "Poppins", Times, serif;
    }
}

.btn-outline-primary:hover {
    color: #ffffff;
    background-color: #008DEA;
    border-color: #008DEA;
}