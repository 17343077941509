// Table

.table-responsive {
    height: 100%;
    overflow: auto;
}

table {
    min-width: 100%;
    table-layout: fixed !important;
}

.mat-icon-button + .mat-icon-button {
    margin-left: 5px;
}

th.mat-header-cell > *,
td.mat-cell > *,
td.mat-footer-cell > * {
    display: block;
    white-space: nowrap;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
}
th.mat-header-cell > * {
    display: flex;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
    padding: 0 8px !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba($table-border-color, 0.2);
    border-top: none;
}

.mat-table-sticky-border-elem-right {
    border-left: 1px solid rgba($table-border-color, 0.2);
}

.mat-table-sticky-border-elem-left {
    border-right: 1px solid rgba($table-border-color, 0.2);
}

th.mat-header-cell {
    background-color: $gray-100;
    border-bottom-color: rgba($table-border-color, 0.2);
}

.mat-row:hover {
    background-color: $gray-100;
}

// Paginator
.mat-paginator {
    background-color: transparent !important;
}