.card-lg{
  border-radius: $card-border-radius*2;
  >.card-img-top{
    border-radius: $card-border-radius*2 $card-border-radius*2 0 0;
  }
  >.card-header{
    padding: $card-spacer-y*2 $card-spacer-x*2;
    color:$light; 
    border-radius: $card-border-radius*2 $card-border-radius*2 0 0;
  }
  >.card-body{
    padding: $card-spacer-y*2 $card-spacer-x*2;
  }
  >.card-img-overlay {
    border-radius: $card-border-radius*2;
    border-bottom:0;
  }
}
.card-gradient{
  >.card-header:first-child{
    background-image: linear-gradient(90deg, $primary 46.87%, $purple 100%);
  }
}

//hover animations

$animate-background: $light !default;
.animate{
  background: $animate-background;
}

@each $state, $value in $theme-colors {
	.animate-#{$state} {
    &::before,&::after{
		  background-color : $value;
    }
	}
}

.card-bg-animate{
  overflow: hidden;
  position: relative;
  z-index: 1;
  .card-body{
    z-index: 1;
  }
	&::before, &::after{
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		display: block;
		transform-origin: 0 50%;
		transition-property: width, height;
		transition-duration: 0.2s;
		transition-timing-function: ease-in;
	}
	&::before{
		height: 55px;
		width: 55px;
		border-top-left-radius: 50px;
	}
	&::after{
		height: 75px;
		width: 75px;
		opacity: 0.3;
		border-top-left-radius: 75px;
	}
	&:hover{
    .card-title, .card-text {
      color: #fff;
    }
		color: #fff;
		border : 1px solid transparent;
		.row{
			z-index: 1;
		}
		&::before,&::after {
			height: 100%;
			bottom: 0px;
			right: 0;
		}
		&::before{
			width: 115%;
			border-bottom-right-radius: 90px;
		}
		&::after{
			width: 100%;
			border-bottom-right-radius: 55px;
		}
	}
}

.card-animate{
  overflow: hidden;
  padding-bottom: 8rem;
  position: relative;
  
  .card-body{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30%;
    width: 100%;
    transition-property: width,height;
    transition: all 300ms;
    padding : 2rem 1rem !important;
    z-index: 1;
    .card-text{
      display: none;
    }
  }
  &::before, &::after{
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		display: block;
		transform-origin: 0 50%;
		transition-property: width, height;
		transition-duration: 0.2s;
		transition-timing-function: ease-in;
	}
	&::after{
		height: 55px;
		width: 55px;
		border-top-left-radius: 50px;
	}
	&::before{
		height: 75px;
		width: 75px;
		opacity: 0.3;
		border-top-left-radius: 75px;
	}
	&:hover{
		.row{
			z-index: 1;
		}
		&::before,&::after {
			height: 100%;
			bottom: 0px;
			right: 0;
		}
		&::before{
			width: 115%;
			border-bottom-right-radius: 90px;
		}
		&::after{
			width: 115%;
			border-top-left-radius: 55px;
		}
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    
    .card-body {
      color: $light;
      height: 100%;
      width: 100%;
      .card-text{
        display: block;
        ul{
          padding-left:1rem ;
        }
      }
    }
  }
}
.card-image-animate{
  position: relative;
  .img-wrapper{
    overflow: hidden;
  }
}

//animation for card hover
.animate-zoom{
  transition: transform .4s;
  &:hover{
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
}

//animation for card hover
.animate-shadow{
  &:hover{
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  }
}

//animation for image hover 
.animate-rotate{
  .img-wrapper{
    img {
      transition: transform .4s;
    }
  }
  &:hover{
    .img-wrapper{
      img{
        transform: rotate(20deg);
        transform-origin: 50% 50%;
      }
    }
  }
}

//animation for image hover 
.animate-scale{
  .img-wrapper{
    img {
      transition: transform .4s;
    }
  }
  &:hover{
    .img-wrapper{
      img{
        transform: scale(1.3);
        transform-origin: 50% 50%;
      }
    }
  }
}